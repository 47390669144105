import fetch from "../utils/fetch";

// 登录
export function login(data) {
  return fetch({
    url: '/api/user/login',
    method: 'Post',
    data: data
  });
}
// 手机号注册
export function phoneRegister(data) {
  return fetch({
    url: '/api/user/register-phone',
    method: 'Post',
    data: data
  });
}
// 邮箱注册
export function emailRegister(data) {
  return fetch({
    url: '/api/user/register-email',
    method: 'Post',
    data: data
  });
}
// 发送邮箱验证码
export function sendRegisterEmailCode(data) {
  return fetch({
    url: '/api/user/send-register-email-code',
    method: 'Post',
    data: data
  });
}

// 验证邮箱验证码
export function verifyRegisterEmailCode(data) {
  return fetch({
    url: '/api/user/verify-register-email-code',
    method: 'Post',
    data: data
  });
}

// 发送短信验证码
export function sendRegisterSmsCode(data) {
  return fetch({
    url: '/api/user/send-register-sms-code',
    method: 'Post',
    data: data
  });
}

// 验证短信验证码
export function verifyRegisterSmsCode(data) {
  return fetch({
    url: '/api/user/verify-register-sms-code',
    method: 'Post',
    data: data
  });
}
