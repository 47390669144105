<template>
  <div class="wap-login register user">
    <div class="flex-center-between top-info">
      <div class="back" @click="back">
        <i class="el-icon-arrow-left"></i>
      </div>
      <div class="flex-center lang" @click="toPath('wapLang')">
        <img :src="langUrl" alt="" />
      </div>
    </div>
    <div class="page-title">{{ $t('zhu-ce') }}</div>

    <div class="tabs flex">
      <div
        class="tab"
        :class="tabIndex == 1 ? 'active' : ''"
        @click="changeTab(1)"
      >
        {{ $t('you-xiang-0') }}
      </div>
      <div
        class="tab"
        :class="tabIndex == 2 ? 'active' : ''"
        @click="changeTab(2)"
      >
        {{ $t('shou-ji-hao') }}
      </div>
    </div>

    <div class="login-form">
      <div class="form-item" v-if="tabIndex == 1">
        <div class="label">{{ $t('you-xiang-0') }}</div>
        <Field
          v-model="form.email"
          :placeholder="$t('qing-shu-ru-you-xiang-3')"
        />
        <div class="form-item">
          <div class="label">{{ $t('yan-zheng-ma') }}</div>
          <Field
            v-model="form.code"
            :placeholder="$t('qing-shu-ru-yan-zheng-ma')"
          >
            <template #button>
              <Button 
                size="small" 
                :disabled="!!countdown || !form.email"
                @click="sendEmailVerifyCode"
              >
                {{ countdown ? `${countdown}s` : $t('huo-qu-yan-zheng-ma') }}
              </Button>
            </template>
          </Field>
        </div>
      </div>
      <div class="form-item" v-if="tabIndex == 2">
        <div class="label">{{ $t('shou-ji-hao') }}</div>
        <Field
          v-model="form.phone"
          :placeholder="$t('qing-shu-ru-shou-ji-hao-5')"
        >
          <template #left-icon>
            <div class="flex-center" @click="changeCountry">
              {{ form.phonePre }}
              <i class="el-icon-caret-bottom"></i>
            </div>
          </template>
        </Field>
        <div class="form-item">
          <div class="label">{{ $t('yan-zheng-ma') }}</div>
          <Field
            v-model="form.code"
            :placeholder="$t('qing-shu-ru-yan-zheng-ma')"
          >
            <template #button>
              <Button 
                size="small" 
                :disabled="!!countdown || !form.phone"
                @click="sendSmsVerifyCode"
              >
                {{ countdown ? `${countdown}s` : $t('huo-qu-yan-zheng-ma') }}
              </Button>
            </template>
          </Field>
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('mi-ma') }}</div>
        <Field
          v-model="form.password"
          :type="showPassword ? 'text' : 'password'"
          :placeholder="$t('qing-shu-ru-mi-ma-2')"
        >
          <template #button>
            <div class="flex-center" @click="showPassword = !showPassword">
              <img :src="eyeUrl" alt="" v-if="!showPassword" />
              <img :src="eyeUrl2" alt="" v-else />
            </div>
          </template>
        </Field>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('que-ren-mi-ma') }}</div>
        <Field
          v-model="form.password2"
          :type="showPassword2 ? 'text' : 'password'"
          :placeholder="$t('qing-shu-ru-mi-ma-3')"
        >
          <template #button>
            <div class="flex-center" @click="showPassword2 = !showPassword2">
              <img :src="eyeUrl" alt="" v-if="!showPassword2" />
              <img :src="eyeUrl2" alt="" v-else />
            </div>
          </template>
        </Field>
      </div>
    </div>

    <div class="form-info flex-center-between">
      <div></div>
      <div class="">
        {{ $t('yi-you-zhang-hao-0') }}
        <span class="blue" @click="toPath('wapUserLogin')">{{
          $t('qu-deng-lu')
        }}</span>
      </div>
    </div>

    <div class="form-btn">
      <Button type="info" @click="submit">{{ $t('li-ji-zhu-ce') }}</Button>
    </div>

    <div class="kefu flex-center">
      <i class="el-icon-service"></i>
    </div>

    <CountryList
      :showDialog.sync="showDialog"
      :phonePre.sync="form.phonePre"
    ></CountryList>
  </div>
</template>
<script>
import { Button, Field } from 'vant'
import CountryList from '@/components/countryList'
import { 
  phoneRegister, 
  emailRegister, 
  sendRegisterEmailCode, 
  verifyRegisterEmailCode,
  sendRegisterSmsCode,
  verifyRegisterSmsCode
} from '@/api/login'

export default {
  components: {
    Button,
    Field,
    CountryList
  },
  data() {
    return {
      form: {
        email: '',
        phone: '',
        phonePre: '+86',
        password: '',
        password2: '',
        code: ''
      },
      showPassword: false,
      showPassword2: false,
      showDialog: false,
      tabIndex: 1,
      eyeUrl: require('@/assets/imgs/icon-eye.png'),
      eyeUrl2: require('@/assets/imgs/icon-eye2.png'),
      langUrl: require('@/assets/imgs/icon-lang.png'),
      countdown: 0
    }
  },
  computed: {
    logo() {
      return this.$store.state.logo
    },
    langIcon() {
      return this.$store.state.langIcon
    },
    lang() {
      return this.$store.state.lang
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    changeTab(index) {
      this.tabIndex = index
    },
    async sendEmailVerifyCode() {
      try {
        if (!this.form.email) {
          this.$toast(this.$t('qing-shu-ru-you-xiang-0'))
          return
        }

        const res = await sendRegisterEmailCode({
          Email: this.form.email
        })
        
        if (res.code === 0) {
          this.$toast(this.$t('yan-zheng-ma-yi-fa-song'))
          this.countdown = 60
          const timer = setInterval(() => {
            this.countdown--
            if (this.countdown <= 0) {
              clearInterval(timer)
            }
          }, 1000)
        } else {
          this.$toast(res.msg || this.$t('fa-song-shi-bai'))
          if (res.msg.includes('请等待')) {
            this.countdown = 60
          }
        }
      } catch (error) {
        console.error(error)
        this.$toast(this.$t('fa-song-shi-bai'))
      }
    },
    async sendSmsVerifyCode() {
      try {
        if (!this.form.phone) {
          this.$toast(this.$t('qing-shu-ru-shou-ji-hao-1'))
          return
        }

        const res = await sendRegisterSmsCode({
          Phone: this.form.phone,
          CountryCode: this.form.phonePre
        })
        
        if (res.code === 0) {
          this.$toast(this.$t('yan-zheng-ma-yi-fa-song'))
          this.countdown = 60
          const timer = setInterval(() => {
            this.countdown--
            if (this.countdown <= 0) {
              clearInterval(timer)
            }
          }, 1000)
        } else {
          this.$toast(res.msg || this.$t('fa-song-shi-bai'))
          if (res.msg.includes('请等待')) {
            this.countdown = 60
          }
        }
      } catch (error) {
        console.error(error)
        this.$toast(this.$t('fa-song-shi-bai'))
      }
    },
    async submit() {
      if (this.tabIndex == 1 && !this.form.email) {
        this.$toast(this.$t('qing-shu-ru-you-xiang-0'))
        return
      }
      if (this.tabIndex == 2 && !this.form.phone) {
        this.$toast(this.$t('qing-shu-ru-shou-ji-hao-1'))
        return
      }
      if (!this.form.code) {
        this.$toast(this.$t('qing-shu-ru-yan-zheng-ma'))
        return
      }
      if (!this.form.password) {
        this.$toast(this.$t('qing-shu-ru-mi-ma-0'))
        return
      }
      if (this.form.password != this.form.password2) {
        this.$toast(this.$t('liang-ci-shu-ru-de-mi-ma-bu-yi-zhi'))
        return
      }

      try {
        if (this.tabIndex == 1) {
          // 验证邮箱验证码
          const verifyRes = await verifyRegisterEmailCode({
            Email: this.form.email,
            Code: this.form.code
          })

          if (verifyRes.code !== 0) {
            this.$toast(verifyRes.msg)
            return
          }

          const res = await emailRegister({
            Email: this.form.email,
            Password: this.form.password,
            PasswordConfirm: this.form.password2,
            Code: this.form.code
          })

          if (res.code == 0) {
            this.$toast(this.$t('zhu-ce-cheng-gong-0'))
            this.toPath('wapUserLogin')
          } else {
            this.$toast(res.msg)
          }
        } else {
          // 验证短信验证码
          const verifyRes = await verifyRegisterSmsCode({
            Phone: this.form.phone,
            CountryCode: this.form.phonePre,
            Code: this.form.code
          })

          if (verifyRes.code !== 0) {
            this.$toast(verifyRes.msg)
            return
          }

          const res = await phoneRegister({
            CountryCode: this.form.phonePre,
            Phone: this.form.phone,
            Password: this.form.password,
            PasswordConfirm: this.form.password2,
            Code: this.form.code
          })

          if (res.code == 0) {
            this.$toast(this.$t('zhu-ce-cheng-gong-0'))
            this.toPath('wapUserLogin')
          } else {
            this.$toast(res.msg)
          }
        }
      } catch (error) {
        console.error(error)
        this.$toast(this.$t('zhu-ce-shi-bai'))
      }
    },
    changeCountry() {
      this.showDialog = true
    },
    toPath(path) {
      this.$router.push({
        name: path
      })
    }
  }
}
</script>